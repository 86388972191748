$(document).ready(function () {
    
    // prevent scrolling when mobile menu is open
    $('.navbar-toggler').on('click', function () {
        if (!$('.navbar-collapse').hasClass('show')) {
            $('body').addClass('noscroll');
        } else {
            $('body').removeClass('noscroll');
        }
    });
    
    // calculate correct height for mobile menu expanded

    $('#mobileNavbarCollapse').on('shown.bs.collapse', function () {
        var mobileMenuHeight = $('.account-nav').outerHeight() + $('.navbar--mobile').outerHeight();
        var dismissableBannerHeight = $('.dismissable-banner').outerHeight()
        if ($(window).scrollTop() < dismissableBannerHeight) {
            mobileMenuHeight += dismissableBannerHeight - $(window).scrollTop();

        }

        $(this).css('height', `calc(100vh - ${mobileMenuHeight}px)`);

    })

    //hide initial messagae on multi registration form when tab is clicked
    $(".registration-multi .nav-link").on('click', function () {
        $(".registration-multi").append('<style>.registration-multi:after{display: none;}</style>');
    });

    $(function () {
        $('.dropdown').on({
            "click": function (event) {
                if ($(event.target).closest('.dropdown-toggle').length) {
                    $(this).data('closable', true);
                } else {
                    $(this).data('closable', false);
                }
            },
            "hide.bs.dropdown": function (event) {
                let hide = $(this).data('closable');
                $(this).data('closable', true);
                return hide;
            }
        });
    });


    if (window.sessionStorage['dismissable-banner-hide'] === 'true') {
        $('.dismissable-banner').css('height', 0);
        $('.dismissable-banner__container').hide();
    }

    $('.dismissable-banner__close').click(() => {
        window.sessionStorage["dismissable-banner-hide"] = "true";
        $('.dismissable-banner').css('height', 0);
        $('.dismissable-banner__container').hide();
    });

    // sticky header
    let header = $("#header");
    let body = $("body");
    let headerTop = header.offset().top;
    let bodyPadding = parseInt(body.css('padding-top'));

    window.onscroll = function () { updateStickyHeader() };

    // If the search bar was expanded at the top of the page on page load, collapse it when the user scrolls, and re-open it if they scroll back.
    // Don't collapse it on scrolls not from top because that means the user opened it manually
    if ($("#header-search-container.show").length) {
        enableScrollFromTopListener();

        $(window).on("scroll", function () {
            if ($(document).scrollTop() <= 0) {
                $("#header-search-container").collapse('show');
                enableScrollFromTopListener();
            }
        });
    }
    
    // If accordions exist, add a listener to collapse the search bar when an accordion is expanded
    let acc = document.querySelectorAll(".accordion");
    let panels = document.getElementsByClassName("panel");
    
    acc.forEach((accordion, index) => {
      accordion.addEventListener("click", function() {
        /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
        this.classList.toggle("active-tile");
        
        var panel = panels[index];
        /* Toggle between hiding and showing the active panel */
        panel.style.display = panel.style.display === 'block' ? 'none' : 'block';
    
      });
    });
    
    // prevent scrolling when mobile menu is open
    $('.navbar-toggler').on('click', function () {
        if (!$('.navbar-collapse').hasClass('show')) {
            $('body').addClass('noscroll');
        } else {
            $('body').removeClass('noscroll');
        }
    });
    

    function enableScrollFromTopListener() {
        $(window).on("scroll.scrollFromTop", function () {
            $("#header-search-container").collapse('hide');
            $(window).off("scroll.scrollFromTop");
        });
    }

    function updateStickyHeader() {
        if (window.pageYOffset >= headerTop) {
            header.addClass("sticky");
            body.css('padding-top', bodyPadding + header.outerHeight());
        } else {
            header.removeClass("sticky");
            body.css('padding-top', bodyPadding);
        }
    }
    updateStickyHeader();

    $(".header-search-toggle-button").click(function (e) {
        if ($(e.currentTarget).hasClass("collapsed")) {
            $("#header-search-container").on("shown.bs.collapse", function () {
                $("#header-search-container input").focus();
                $("#header-search-container").unbind("shown");
            });
        }
    });

    // require jquery for flickity
    var jQueryBridget = require('jquery-bridget');
    var Flickity = require('flickity');

    // make Flickity a jQuery plugin
    Flickity.setJQuery($);
    jQueryBridget('flickity', Flickity, $);
});