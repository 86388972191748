let toExport = {};
import fetch from 'cross-fetch';

(function ($) {
    var errorElementClass = 'has-error';
    var ogAddress1;
    var ogAddress2;
    var ogCity;
    var ogState;
    var ogPostalCode;
    var ogForm;
    var suggestedAddress;
    var requestedAddress;
    var thisForm;

    function toggleFormFields(isDisabled) {
        $("#address-validation-form input").prop("readonly", isDisabled);
        $("#address-validation-form select").prop("disabled", isDisabled);
    }

    function postCredentialedJson(data) {
        const postCredentialedJsonInit = {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'fetch'
            }
        };
        let init = { body: JSON.stringify(data) }
        Object.assign(init, postCredentialedJsonInit)
        return init;
    }

    async function showAddressValidationModal(address1, address2, city, state, zip, form) {
        const modal = document.getElementById("addressValidation");
        ogAddress1 = address1;
        ogAddress2 = address2;
        ogCity = city;
        ogState = state;
        ogPostalCode = zip;
        ogForm = form;

        var request = {
            addressLine1: ogAddress1.val(),
            addressLine2: ogAddress2.val(),
            city: ogCity.val(),
            state: ogState.find("option:selected").val(),
            postalCode: ogPostalCode.val()
        }

        var response = await validateAddress(request);
        suggestedAddress = response.SuggestedAddress;
        requestedAddress = response.RequestedAddress;
        if (response.HasAddressSuggestion && response.SuggestedAddress != null) {
            $('#requestedAddress').text(createAddressString(response.RequestedAddress));
            if (response.SuggestedAddress.AddressLine1) {
                $('.suggested-address').text(createAddressString(response.SuggestedAddress));
                $('input[value=Suggested]', modal).prop('checked', true);
                setModalFields(response.SuggestedAddress, true);
            }
            else {
                $('#suggestedAddress').hide();
                setModalFields(response.RequestedAddress, false);
            }

            $(modal).modal('show');
        }
        else {
            form.submit();
        }
    }

    function setModalFields(addressToSet, disableFields) {
        $('#AddressLine1', thisForm).val(addressToSet.AddressLine1);
        $('#AddressLine2', thisForm).val(addressToSet.AddressLine2);
        $('#City', thisForm).val(addressToSet.City);
        $('#PostalCode', thisForm).val(addressToSet.PostalCode);
        setDropDownByTextOrValue('#StateList', addressToSet.State);
        toggleFormFields(disableFields);
    }

    function onSave() {
        ogAddress1.val($('#AddressLine1', thisForm).val());
        ogAddress2.val($('#AddressLine2', thisForm).val());
        ogCity.val($('#City', thisForm).val());
        ogPostalCode.val($('#PostalCode', thisForm).val());
        var selectedStateName = $('#StateList option:selected', thisForm).text();
        setDropDownByTextOrValue('#' + ogState.attr('id'), selectedStateName);

        const modal = document.getElementById("addressValidation");
        $(modal).modal('hide');
        ogForm.submit();
    }

    function validateAddressValidationForm() {
        thisForm.validate({
            rules: {
                AddressLine1: {
                    required: true
                },
                City: {
                    required: true
                },
                PostalCode: {
                    required: true,
                    USZipCode: true
                },
            },
            errorClass: 'invalid-feedback',
            errorPlacement: function (error, element) {
                element.before(error);
            },
            highlight: function (element) {
                $(element.parentNode).addClass(errorElementClass);
            },
            unhighlight: function (element) {
                $(element.parentNode).removeClass(errorElementClass);
            }
        });
    }

    function setDropDownByTextOrValue(selectListId, textOrValue) {
        $(selectListId + ' option').filter(function () { return $(this).text() == textOrValue || $(this).val() == textOrValue; }).prop("selected", true);
    }

    async function validateAddress(request) {
        var url = window["validationUrl"];
        return new Promise((resolve, reject) => {
            fetch(url, postCredentialedJson(request))
                .then(response => {
                    if (response.status == 200) {
                        resolve(response.json());
                    }
                    else {
                        resolve(response);
                    }
                })
                .then(message => {
                    reject(message);
                })
                .catch(error => {
                    alert(error);
                });
        });
    }

    function createAddressString(addressObject) {
        return addressObject.AddressLine1 + " " + (addressObject.AddressLine2 ? addressObject.AddressLine2 + " " : "") + addressObject.City + ", " + addressObject.State + " " + addressObject.PostalCode
    }

    toExport.showAddressValidationModal = showAddressValidationModal;

    $(document).ready(() => {
        thisForm = $('#address-validation-form');
        thisForm.submit(function (e) {
            e.preventDefault();
            validateAddressValidationForm();
            if (!thisForm.valid()) {
                return false;
            }

            onSave();
        });

        if ($("input[value='Suggested']") != null && $("input[value='Suggested']").is(':checked')) {
            toggleFormFields(true);
        }

        $("input[value='Suggested']").on("change", function () {
            setModalFields(suggestedAddress, true);
            toggleFormFields(true);
        });

        $("input[value='Original']").on("change", function () {
            setModalFields(requestedAddress, true);
            toggleFormFields(true);
        });

        $("input[value='Edited']").on("change", function () {
            setModalFields(requestedAddress, false);
            toggleFormFields(false);
        });
    });
})(jQuery);

export default toExport;