import {loadRecaptchaScript} from "../util/recaptchaV3";

(function ($) {
    function toggleVisibleFieldsbyTicketType(type) {
        $("div[data-ticket-type], .ticket-category-wrapper *[data-ticket-type]").each((i, el) => {
            $(el).toggleClass("d-none", type !== $(el).attr("data-ticket-type"));
        });
        $("div[data-ticket-type-except], .ticket-category-wrapper *[data-ticket-type-except]").each((i, el) => {
            $(el).toggleClass("d-none", type === $(el).attr("data-ticket-type-except"));
        });
        if ($(".ticket-category option:selected").is(":hidden")) {
            $(".ticket-category").val("");
        }
        $('#contactUsBtn').addClass(type === "QT" ? "ga-generate_lead-quote" : "ga-generate_lead-contact-us");
    }

    function toggleVisibleEquipmentIssuesByEquipmentCode(code) {
        $(".equipment-incident-issue option[data-equipment-code]").each((i, el) => {
            $(el).toggleClass("d-none", code !== $(el).attr("data-equipment-code"));
        });
        if ($(".equipment-incident-issue option:selected").is(":hidden")) {
            $(".equipment-incident-issue").val("");
        }
        $("div[data-ticket-type][data-ticket-category-except]").each((i, el) => {
            $(el).toggleClass("d-none-2", code === $(el).attr("data-ticket-category-except"));
        });
    }

    $(document).ready(() => {
        // Apply filters on load if options already selected
        var queryParams = {};
        if (!!window.location.search) {
            window.location.search.substr(1).split("&").forEach(function (item) { queryParams[item.split("=")[0].toLowerCase()] = item.split("=")[1] });
            if (!!queryParams["tickettype"]) {
                $('.contact-us-form select[name="ContactUsForm.TicketTypeId"] option[data-ticket-type="' + queryParams["tickettype"] + '"').prop('selected', true);
            }
        }
        
        toggleVisibleFieldsbyTicketType($(".contact-us-form .ticket-type option:selected").attr("data-ticket-type") || "");
        if (!$(".contact-us-form .ticket-category").is(":hidden")) {
            toggleVisibleEquipmentIssuesByEquipmentCode($(".contact-us-form .ticket-category option:selected").attr("data-ticket-category") || "");
        }

        if (!!queryParams["ticketcategory"]) {
            $('.contact-us-form select[name="ContactUsForm.TicketCategoryId"] option[data-ticket-category="' + queryParams["ticketcategory"] + '"').prop('selected', true);
        }

        // Initialize event handlers
        $(".contact-us-form .ticket-type").change((e) => {
            const typeCode = $(e.target).find("option:selected").attr("data-ticket-type") || "";
            toggleVisibleFieldsbyTicketType(typeCode);
            toggleVisibleEquipmentIssuesByEquipmentCode("");
        });

        $(".contact-us-form .ticket-category").change((e) => {
            const categoryCode = $(e.target).find("option:selected").attr("data-ticket-category") || "";
            toggleVisibleEquipmentIssuesByEquipmentCode(categoryCode);
        });

        const recaptchaSiteKey = $('#contact-us-form').attr("data-recaptcha-site-key");
        
        if(recaptchaSiteKey !== undefined || recaptchaSiteKey !== null || recaptchaSiteKey !== "") {
            loadRecaptchaScript(recaptchaSiteKey);
        }
        
        $('#contact-us-form').submit(function (e) {
            e.preventDefault();

            if (!$(this).valid()) {
                return false;
            }

            if(recaptchaSiteKey === undefined || recaptchaSiteKey === null || recaptchaSiteKey === "" || grecaptcha === undefined) {
                console.log("Recaptcha site key is not defined.");
                $('.contact-us-error').removeClass("d-none");
                return false;
            }
            
            grecaptcha.ready(function() {
                grecaptcha.execute(recaptchaSiteKey, {action: 'submit'}).then(function(token) {
                    if($("#contact-us-form [name=g-recaptcha-response]").length > 0) {
                        $("#contact-us-form [name=g-recaptcha-response]").remove();
                    }
                    $('#contact-us-form').append('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                    $('.contact-us-error').addClass("d-none");
                    $('.js-submit-button').attr("disabled", "disabled");

                    $.ajax({
                        url: $('#contact-us-form').attr("data-submit-url"),
                        type: 'post',
                        data: $('#contact-us-form').serialize(),
                        success: function (data) {
                            // Whatever you want to do after the form is successfully submitted
                            if (!!data.Success) {
                                $('#contact-us-form').addClass("d-none");
                                $('.contact-us-intro-paragraph').addClass("d-none");
                                $(".contact-us-thank-you").removeClass("d-none");
                            } else {
                                //$('.contact-us-error').removeClass("d-none");
                                //$('.js-submit-button').removeAttr("disabled", "disabled");
                            }
                        },
                        error: function () {
                            //console.log("error");
                            //$('.contact-us-error').removeClass("d-none");
                        },
                        complete: function (data) {
                            //console.log("finally");
                            if (!data.success) {
                                $('.contact-us-error').removeClass("d-none");
                                $('.js-submit-button').removeAttr("disabled", "disabled");
                            }
                        }
                    });
                });
            });
        });
    });
})(jQuery);