import './util/polyfills';

import 'flickity';
import 'flickity-as-nav-for';

import tileCarousels from './carousels/tileCarousels';

// use unobtrusive validation. additional validation can be added.
//import _ from "jquery-validation-unobtrusive";

// make jQuery.validate mark bootstarp forms with correct jQuery classes
jQuery.validator.setDefaults({
    highlight: function (element) {
        jQuery(element).addClass('is-invalid');
    },
    unhighlight: function (element) {
        jQuery(element).removeClass('is-invalid');
      jQuery(element).siblings('.field-validation-error').empty();
    },
    errorElement: 'div',
    errorClass: 'invalid-feedback',
    errorPlacement: function (error, element) {
        if (element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    }
});

import './product';
import { showMiniCart, hideMiniCart, refreshMiniCart, refreshAndShowMiniCart } from './cart';
import './skin';
import './registration';
import './login';
import './MyAccount/wallet';
import addressValidation  from './shared/addressValidation';
import invoiceEventBus from "./util/invoiceEventBus";
import ga4TrackingUtility from "./analytics/ga4TrackingUtility";
import orderHistory from "./MyAccount/orderHistory";
import './impersonation';
import './contactus';

global.jQuery = $;
window.jQuery = $;
window.$ = $;

$(document).ready(() => {
    tileCarousels();
}
);

global["MiniCart"] = global["MiniCart"] || {}
global.MiniCart.show = showMiniCart;
global.MiniCart.hide = hideMiniCart;
global.MiniCart.refresh = refreshMiniCart;
global.MiniCart.refreshAndShow = refreshAndShowMiniCart;
global.addressValidation = addressValidation;
global.invoiceEventBus = invoiceEventBus;
global.ga4TrackingUtility = ga4TrackingUtility;
global.orderHistory = orderHistory;


//import eac_css from 'easy-autocomplete/src/sass/easy-autocomplete.scss';
import EasyAutocomplete from "easy-autocomplete";
global.EasyAutocomplete = EasyAutocomplete;

import { formDataToBody, makeFormData } from '../../../CRA/src/Components/util/catalystFormDataBuilder';
import { makeCatalystFetchPostInit } from '../../../CRA/src/Components/util/fetchUtility';
global.FormDataUtil = {
    makeFormData,
    formDataToBody,
    makeCatalystFetchPostInit
}