import editModal from './walletCardEditModal';
import addUsBankAccountAddModal from './walletUsBankAccountAddModal';
import { makeCatalystFetchPostInit } from '../../../../CRA/src/Components/util/fetchUtility';
import invoiceEventBus from '../util/invoiceEventBus';

function walletConfig() {
    return window["walletConfig"] || {
        addedCardUrl: '',
        deleteUrl: '',
        tokenUrl: '',
        replaceCardUrl: '',
        deleteUsBankAccountUrl: '',
        verifyUsBankAccountMicroTransactionsUrl: '',
        editTitle: '',
        addTitle: '',
        editButton: '',
        addButton: '',
        confirmAccountDeletion: '',
        confirmCardDeletion: '',
        errorWhileTryingToDeleteAccount: '',
        errorWhileTryingToDeleteCard: '',
        requestFailed: '',
        invalidAmounts: '',
        confirmMicroTransactionVerify: '',
        errorWhileTryingToVerifyAccount: ''
    }
}

function onCardDelete(cardElement) {
    return () => {
        let label = cardElement.querySelector(".credit-card-label").innerHTML;
        let confirmMessage = walletConfig().confirmCardDeletion + " " + label;
        if (!confirmDelete(confirmMessage)) {
            return;
        }
        const url = walletConfig().deleteUrl;
        fetch(url, makeCatalystFetchPostInit({ cardId: cardElement.dataset.paymentMethodId }))
            .then(response => response && response.text() || "")
            .then(message => {
                if (message) {
                    alertError(walletConfig().errorWhileTryingToDeleteCard + ":\n" + message);
                } else {
                    invoiceEventBus.dispatch("paymentsUpdated", { message: "payments updated" });
                    cardElement.querySelectorAll("button").forEach(btn => btn.onclick = null);
                    cardElement.parentElement.remove();
                }
            })
            .catch(() => {
                alertError(walletConfig().requestFailed);
            });
    }
}

function onUsBankAccountDelete(usBankAccountElement) {
    return () => {
        let label = usBankAccountElement.querySelector(".us-bank-account-label").innerHTML;
        let confirmMessage = walletConfig().confirmAccountDeletion + " " + label;
        if (!confirmDelete(confirmMessage)) {
            return;
        }
        const url = walletConfig().deleteUsBankAccountUrl;
        fetch(url, makeCatalystFetchPostInit({ usBankAccountToken: usBankAccountElement.dataset.paymentMethodId }))
            .then(response => response.json())
            .then(response => {
                if (!response || !response.Success) {
                    alertError(walletConfig().errorWhileTryingToDeleteAccount +
                        (response.ErrorMessage ? "\n" + response.ErrorMessage : ""));
                } else {
                    invoiceEventBus.dispatch("paymentsUpdated", { message: "payments updated" });
                    usBankAccountElement.querySelectorAll("button").forEach(btn => btn.onclick = null);
                    usBankAccountElement.parentElement.remove();
                }
            })
            .catch(() => {
                alertError(walletConfig().requestFailed);
            });
    }
}

function onEditCard(cardElement) {
    return async () => {
        try {
            const existingData = extractExistingCardData(cardElement);
            const payload = await editModal.showCardEditModal(`${walletConfig().editTitle} ${existingData.cardNumber}`, walletConfig().editButton, existingData);
            if (payload) {
                reloadPage();
            }
        } catch (err) {
            alertError(err);
        }
    }
}

async function onAddCard() {
    try {
        const payload = await editModal.showCardEditModal(walletConfig().addTitle, walletConfig().addButton, extractExistingCardData());
        if (payload) {
            if (!!payload.details && !!payload.details.lastFour) {
                fetch(walletConfig().addedCardUrl, makeCatalystFetchPostInit({ lastFour: payload.details.lastFour }))
                    .then((data) => { reloadPage(); });
            } else {
                reloadPage();
            }
        }
    } catch (err) {
        alertError(err);
        reloadPage();
    }
}

function extractExistingCardData(element) {
    if (element) {
        const data = { cardNumber: null, expirationDate: null, type: null }
        if (element.dataset.cardNumber) {
            data.cardNumber = element.dataset.cardNumber;
        }
        if (element.dataset.cardExpirationDate) {
            data.expirationDate = element.dataset.cardExpirationDate;
        }
        if (element.dataset.cardType) {
            data.type = element.dataset.cardType;
        }
        if (element.dataset.cardIsPrimary.toLowerCase() === 'true') {
            data.isPrimary = true;
        }
        if (element.dataset.cardBin) {
            data.bin = element.dataset.cardBin;
        }
        if (element.dataset.cardBin) {
            data.bin = element.dataset.cardBin;
        }
        if (element.dataset.cardStreetAddress) {
            data.streetAddress = element.dataset.cardStreetAddress;
        }
        if (element.dataset.cardExtendedAddress) {
            data.extendedAddress = element.dataset.cardExtendedAddress;
        }
        if (element.dataset.cardLocality) {
            data.locality = element.dataset.cardLocality;
        }
        if (element.dataset.cardRegion) {
            data.region = element.dataset.cardRegion;
        }
        if (element.dataset.cardPostalCode) {
            data.postalCode = element.dataset.cardPostalCode;
        }
        if (element.dataset.paymentMethodId) {
            data.cardId = element.dataset.paymentMethodId;
        }
        return data;
    }
    return null;
}

function alertError(error) {
    alert(error);
}

function confirmDelete(message) {
    return confirm(message);
}

async function onAddUsBankAccount() {
    try {
        const payload = await addUsBankAccountAddModal.showUsBankAccountAddModal();
        if (payload) {
            reloadPage()
        }
    } catch (err) {
        alertError(err);
    }
}

function onUsBankAccountVerifyMicroTransactions(usBankAccountElement) {
    return (e) => {
        e.preventDefault();
        let label = usBankAccountElement.querySelector(".us-bank-account-label").innerHTML;
        let transaction1;
        let transaction2;
        try {
            transaction1 = parseFloat(usBankAccountElement.querySelector(".micro-transaction-1").value);
            transaction2 = parseFloat(usBankAccountElement.querySelector(".micro-transaction-2").value);
        } catch (e) {
            alertError(walletConfig().invalidAmounts);
            return;
        }

        let confirmMessage = walletConfig().confirmMicroTransactionVerify + " " + label;
        if (!confirm(confirmMessage)) {
            return;
        }
        const url = walletConfig().verifyUsBankAccountMicroTransactionsUrl;
        const data = {
            usBankAccountToken: usBankAccountElement.dataset.paymentMethodId,
            transaction1: transaction1 % 1 !== 0 ? transaction1 * 100 : transaction1,
            transaction2: transaction2 % 1 !== 0 ? transaction2 * 100 : transaction2
        };

        const submitButton = usBankAccountElement.querySelector("button[type='submit']");
        submitButton.setAttribute("disabled", "disabled");

        fetch(url, makeCatalystFetchPostInit(data))
            .then(response => response.json())
            .then(response => {
                if (!response || !response.Success) {
                    alertError(walletConfig().errorWhileTryingToVerifyAccount +
                        (response.ErrorMessage ? "\n" + response.ErrorMessage : ""));
                    submitButton.removeAttribute("disabled");
                } else {
                    reloadPage();
                }
            })
            .catch(() => {
                alertError(walletConfig().requestFailed);
                submitButton.removeAttribute("disabled")
            });
    }
}

function reloadPage() {
    window.location.href = window.location.href.split('#')[0] + "#wallet";
    window.location.reload();
}

document.addEventListener("DOMContentLoaded", () => {
    const cardElements = document.querySelectorAll(".credit-cards .credit-card");
    cardElements.forEach(cardElement => {
        const deleteButton = cardElement.querySelector("button.delete");
        const editButton = cardElement.querySelector("button.edit");
        if (deleteButton) deleteButton.onclick = onCardDelete(cardElement);
        if (editButton) editButton.onclick = onEditCard(cardElement);
    });

    const addNewCardButton = document.querySelector(".credit-cards button.add-new-card");
    if (addNewCardButton)
        addNewCardButton.onclick = onAddCard;

    const usBankAccountElements = document.querySelectorAll(".us-bank-accounts .us-bank-account");
    usBankAccountElements.forEach(usBankAccountElement => {
        const deleteButton = usBankAccountElement.querySelector("button.delete");
        if (deleteButton) deleteButton.onclick = onUsBankAccountDelete(usBankAccountElement);
        const verifyMicroTransactionsForm = usBankAccountElement.querySelector("form");
        if (verifyMicroTransactionsForm) {
            verifyMicroTransactionsForm.onsubmit = onUsBankAccountVerifyMicroTransactions(usBankAccountElement);
        }
    });

    const addNewUsBankAccountButton = document.querySelector(".us-bank-accounts button.add-new-us-bank-account");
    if (addNewUsBankAccountButton)
        addNewUsBankAccountButton.onclick = onAddUsBankAccount;
    
    invoiceEventBus.on("reloadWallet", () => {
        const walletNavTab = document.getElementById('nav-wallet-tab');
        if(walletNavTab) {
            walletNavTab.addEventListener("click", (e) => {
                e.preventDefault();
                reloadPage();
            })
        }
    });
});
