const errorElementClass = 'has-error';
(function ($) {
    

    function doInit() {
        
        function hasSignUpCode() {
            return $("input[name='HaveRegistrationCode']:checked").val() === "True";
        }

        function doesNotHaveSignUpCode() {
            return $("input[name='HaveRegistrationCode']:checked").val() === "False";
        }

        function grecaptchaV3SubmitHandler(form, event) {

            const $form = $(form);
            const recaptchaSiteKey = $form.attr("data-recaptcha-site-key");
            if(recaptchaSiteKey === undefined || recaptchaSiteKey === null || recaptchaSiteKey === "" || grecaptcha === undefined) {
                console.log("Recaptcha site key is not defined.");
                $('.contact-us-error').removeClass("d-none");
                return false;
            }

            grecaptcha.ready(function() {
                // get recaptcha site key from the form
                grecaptcha.execute(recaptchaSiteKey, {action: 'submit'}).then(function(token) {
                    if($form.closest("[name=g-recaptcha-response]").length > 0) {
                        $form.closest("[name=g-recaptcha-response]").remove();
                    }
                    $form.append('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');

                    // Submit the form
                    form.submit();
                });
            });
        }

        // Existing Business Registration Page Validation
        $('#registerExistingBusinessForm').validate({
            rules: {
                FirstName: {
                    required: true
                },
                LastName: {
                    required: true
                },
                Email: {
                    email: true,
                    required: true
                },
                Phone: {
                    USPhoneNumber: true,
                    required: true
                },
                Password: {
                    required: true,
                    minlength: 8,
                    requireNumber: true,
                    requireMixedCase: true,
                    onlyAllowCharacters: true,
                    requireNonAlphaNumeric: true
                },
                ConfirmationPassword: {
                    required: true,
                    equalTo: "#Password"
                },
                HaveRegistrationCode: {
                    required: true
                },
                RegistrationCode: {
                    required: hasSignUpCode
                },
                AccountNumber: {
                    required: doesNotHaveSignUpCode
                }
            },
            errorClass: 'invalid-feedback',
            errorPlacement: function (error, element) {
                if (element.is(":radio")) {
                    error.insertAfter(element.closest('.input-container').find('label').first());
                } else {
                    element.before(error);
                }
            },
            highlight: function (element) {
                $(element.parentNode).addClass(errorElementClass);
            },
            unhighlight: function (element) {
                $(element.parentNode).removeClass(errorElementClass);
            },
            submitHandler: grecaptchaV3SubmitHandler
        });

        // New Customer Registration Page Validation
        $('#newCustomerRequestForm').validate({
            rules: {
                FirstName: {
                    required: true
                },
                LastName: {
                    required: true
                },
                Email: {
                    email: true,
                    required: true
                },
                Phone: {
                    USPhoneNumber: true,
                    required: true
                },
                TypeOfBusiness: {
                    required: true
                },
                NameOfBusiness: {
                    required: true
                },
                AddressLine1: {
                    required: true
                },
                City: {
                    required: true
                },
                State: {
                    required: true
                },
                PostalCode: {
                    required: true,
                    USZipCode: true
                },
            },
            errorClass: 'invalid-feedback',
            errorPlacement: function (error, element) {
                element.before(error);
            },
            highlight: function (element) {
                $(element.parentNode).addClass(errorElementClass);
            },
            unhighlight: function (element) {
                $(element.parentNode).removeClass(errorElementClass);
            },
            submitHandler: grecaptchaV3SubmitHandler
        });
    }

    $(document).ready(doInit);
})(jQuery);