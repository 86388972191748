// BBS DDD 2018-03-08 Shim IE
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger
Number.isInteger = Number.isInteger || function (value) {
  return typeof value === 'number' &&
    isFinite(value) &&
    Math.floor(value) === value;
};

(function ($) {
    $(document).ready(function () {

        $('#primary-image, #biggerImage').click(function () {
            var content = '<img class="img-fluid" src="' + $('#primary-image').attr('src') + '"/>';
            var html = '<div id="imagePopUpModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
            html += '<div class="modal-dialog modal-lg">';
            html += '<div class="modal-content">';
            html += '<div class="modal-header">';
            html += '<h4 class="headline headline-sm">' + $('#primary-image').data('title') + '</h4>';
            html += '<a data-dismiss="modal"><i class="fas fa-times"></i></a>';
            html += '</div>';
          html += '<div class="modal-body text-center">';
            html += content;
            html += '</div>';
            html += '</div>';  // dialog
            html += '</div>';  // footer
            html += '</div>';  // modalWindow
            $('body').append(html);
            $("#imagePopUpModal").modal();
            $("#imagePopUpModal").modal('show');

            $('#imagePopUpModal').on('hidden.bs.modal', function (e) {
                $(this).remove();
            });
        });

        // change primary image by click
        $(document).on("click", ".product-details-alt-images-item > img", function () {
            $("#primary-image")
                .attr("src", $(this).attr("src"))
                .attr("title", $(this).attr("title"));
        });

        // change variant info when you select a variant
        $(document).on("change", "#variations-select", function () {
            if (!window.variations)
                return;

            var code = $(this).val();
            $(".price-wrapper").addClass("d-none");
            $(".variation-details").addClass("d-none");
            $(".add-to-cart-parent").addClass("d-none");
            $(".variant-status-hint-wrapper").addClass("d-none");
            $(".price-contact-us-for-price").addClass("d-none");

            for (var i = 0; i < window.variations.length; i++) {
                if (window.variations[i].Code === code) {
                    $(".js-variation .js-addtocart-code").data("code", code);
                    $("#price-wrapper-" + code).removeClass("d-none");
                    $("#variation-details-" + code).removeClass("d-none");
                    if (!!window.variations[i].VariantStatusLongDescription) {
                        $(".variant-status-hint-wrapper").removeClass("d-none");
                        $(".variant-status-hint").html(window.variations[i].VariantStatusLongDescription);
                    }
                    $(".add-to-cart-parent").toggleClass("d-none", !window.variations[i].CanAddToCart);
                    $(".price-contact-us-for-price").toggleClass("d-none", window.variations[i].PriceViewModel && !window.variations[i].PriceViewModel.ShowPrice );
                    $(".product-more-info").html(window.variations[i].Description);
                    break;
                }
            }
        });

        $(document).on("click", "#more-info-link", function (event) {
            event.preventDefault();
            var scrollTop = $("#more-info-wrapper").offset().top - $(".header-botom-top-wrapper").height();
            $("body,html").animate({ scrollTop: scrollTop }, 200);
        });
    });
})($);