export function loadRecaptchaScript(recaptchaSiteKey, callback) {
    if (!document.getElementById('recaptcha-script')) {
        var script = document.createElement('script');
        script.id = 'recaptcha-script';
        script.src = 'https://www.google.com/recaptcha/api.js?render=' + recaptchaSiteKey;
        script.async = true;
        script.defer = true;
        script.onload = callback;
        document.head.appendChild(script);
    } else if (typeof callback === 'function') {
        callback();
    }
}