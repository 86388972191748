import Flickity from 'flickity';

const init = () => {
    window.tileCarousels = [];

    const carousels = document.querySelectorAll('.flickity-tile-carousel');

    if (carousels) {
        carousels.forEach((item) => {
            let params = item.dataset.flickityInitParams;

            try {
                params = JSON.parse(params);
                var flkty = new Flickity(item, params);
                flkty.visibleItemsCount = params.visibleItemsCount;
                window.tileCarousels.push(flkty);
            } catch (err) {
                console.warn(`Flickity slider: Can't initialize`, err);
            }
        });
    }

    const allowDrag = (e) => {

        const intViewportWidth = window.innerWidth;

        window.tileCarousels.forEach(carousel => {
            const isDragDisabled = intViewportWidth > 991;

            if (isDragDisabled) {
                carousel.select(0);
                carousel.isDraggable = false;
            } else {
                carousel.isDraggable = true;
            }
        });
    }

    allowDrag();

    window.addEventListener("resize", allowDrag);

}

export default init;