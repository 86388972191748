import 'jquery-validation';

(function ($) {
  var errorElementClass = "has-error";

  $.validator.setDefaults({
    debug: false,
    success: "valid",
  });

  function doInit() {
    /*
     * Rules defined in jquery.validation-ExtensionRules.js
     *   requireNumber 
     *   requireMixedCase
     *   onlyAllowCharacters
     *   requireNonAlphaNumeric
     *   USPhoneNumber
     *   USZipCode
     */

    $('#addToShoppingCartForm').validate({
      highlight: function (element) {
        $(element.parentNode).addClass(errorElementClass);
        $('#addToShoppingCartForm #Qty-error.alert.alert-danger').removeClass('d-none');
      },
      unhighlight: function (element) {
        $(element.parentNode).removeClass(errorElementClass);
        $('#addToShoppingCartForm #Qty-error.alert.alert-danger').addClass('d-none');
      },
      errorPlacement: function (error, element) {
        error.insertBefore(element.parent());
      },
      errorClass: "alert alert-danger",
      errorElement: "div",
      rules: {
        Qty: {
          digits: true,
          min: 1,
        },
      },
      messages: {
        Qty: {
          digits: "Quantity must be valid number!",
        }
      } 
    });
  }

  $(document).ready(function () {
    doInit();
  });
})($);