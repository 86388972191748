(function ($) {
    $(document).ready(function () {
        $(".js-forgot-password-link").on("click", function (e) {
            e.preventDefault();
            $("#resetPasswordCard").removeClass("d-none");
            $("#resetPasswordEmail").focus();
        });
    });
})($);

