var errorElementClass = 'has-error';

function elementIsNotHidden(el) {
    //console.log($(el).attr('name') + " - " + !$(el).is(":hidden") + " - " + $(el).val());
    return !$(el).is(":hidden");
}

(function ($) {
    function doInit() {
        $('#contact-us-form').validate({
            rules: {
                "ContactUsForm.NameOfBusiness": {
                    required: true
                },
                "ContactUsForm.FirstName": {
                    required: true
                },
                "ContactUsForm.LastName": {
                    required: true
                },
                "ContactUsForm.Phone": {
                    required: true,
                    USPhoneNumber: true
                },
                "ContactUsForm.BusinessEmailAddress": {
                    required: true,
                    email: true
                },
                "ContactUsForm.TicketCategory": {
                    required: true
                },
                "ContactUsForm.EquipmentIncidentCategory": {
                    required: elementIsNotHidden
                },
                "ContactUsForm.EquipmentIncidentIssue": {
                    required: elementIsNotHidden
                },
                "ContactUsForm.TypeOfBusiness": {
                    required: elementIsNotHidden
                },
                "ContactUsForm.AddressLine": {
                    required: elementIsNotHidden
                },
                "ContactUsForm.City": {
                    required: elementIsNotHidden
                },
                "ContactUsForm.Country": {
                    required: elementIsNotHidden
                },
                "ContactUsForm.State": {
                    required: elementIsNotHidden
                },
                "ContactUsForm.PostalCode": {
                    required: elementIsNotHidden,
                    USZipCode: true
                },
                "ContactUsForm.AnythingElse": {
                    //required: elementIsNotHidden
                }
            },
            errorClass: 'invalid-feedback',
            errorPlacement: function (error, element) {
                element.after(error);
            },
            highlight: function (element) {
                $(element.parentNode).addClass(errorElementClass);
            },
            unhighlight: function (element) {
                $(element.parentNode).removeClass(errorElementClass);
            },
            messages: {
                //"ContactUsForm.NameOfBusiness": "Please provide your company's name.",
                //"ContactUsForm.FirstName": "Please provide your first name.",
                //"ContactUsForm.LastName": "Please provide your last name.",
                //"ContactUsForm.Phone": "Please provide a valid phone number.",
                //"ContactUsForm.BusinessEmailAddress": "Please provide a valid email address.",
                //"ContactUsForm.TicketCategory": "Please select a reason for your contacting us so we can better provide you with help.",
                //"ContactUsForm.EquipmentType": "Please specify the type of equipment you're contacting us about.",
                //"ContactUsForm.EquipmentIncidentCategory": "",
                //"ContactUsForm.TypeOfBusiness": "",
                //"ContactUsForm.AddressLine": "",
                //"ContactUsForm.City": "",
                //"ContactUsForm.Country": "",
                //"ContactUsForm.State": "",
                //"ContactUsForm.PostalCode": "",
                //"ContactUsForm.AnythingElse": "",
            }
        });
    }

    $(document).ready(doInit);
})(jQuery);