import $ from 'jquery';
import './registration-validation';
import { loadRecaptchaScript } from "../util/recaptchaV3";

(function () {
    const haveRegistrationCodeSelector = "input[name=HaveRegistrationCode]";
    
    document.addEventListener("DOMContentLoaded", function () {

        if (window.registerExistingBusinessForm) {
            const $form = $('#registerExistingBusinessForm');
            const $adminAccount = $form.find("#admin-account");
            const $nonAdminAccount = $form.find("#non-admin-account");

            function showHideSignupCode (adminForAccountString) {
                if(!adminForAccountString) {
                    $adminAccount.hide();
                    $nonAdminAccount.hide();
                } else if(adminForAccountString.toLowerCase() === "true") {
                    $adminAccount.show();
                    $nonAdminAccount.hide();
                } else if(adminForAccountString.toLowerCase() === "false") {
                    $adminAccount.hide();
                    $nonAdminAccount.show();
                }
            }

            showHideSignupCode($form.find(haveRegistrationCodeSelector + ":checked").val());

            $form.on('change', haveRegistrationCodeSelector, function () {
                showHideSignupCode($(this).val());
            });

            disableRegisterButton($form);

            loadRecaptchaV3($form);
        }

        if (window.newCustomerRequestForm) {
            const $newCustomerForm = $('#newCustomerRequestForm');
            disableRegisterButton($newCustomerForm);
            loadRecaptchaV3($newCustomerForm);
        }

        function disableRegisterButton(form) {
            form.find('#registerNewBtn').on('click', (e) => {
                if (form.valid() && !e.target.disabled) {
                    form.submit();
                    e.target.setAttribute('style', 'background-color: gray; border-color: gray');
                    e.target.disabled = true;
                }                    
            });
        }

        function loadRecaptchaV3($form) {
            const recaptchaSiteKey = $form.attr("data-recaptcha-site-key");
            if(recaptchaSiteKey !== undefined || recaptchaSiteKey !== "") {
                loadRecaptchaScript(recaptchaSiteKey);
            }
        }

    });
})();