const ga4TrackingUtility = {
    cartEcommerceEvent(event, currency, value, items) {
      if (typeof(window.dataLayer)==='undefined') { return; }
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        'event': event,
        'ecommerce': {
          'currency': currency,
          'value': value,
          'items': items
        }
      });
    },
    viewItemListEvent(itemListId, itemListName, items) {
      if (typeof(window.dataLayer)==='undefined') { return; }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        'event': 'view_item_list',
        'ecommerce': {
          'item_list_id': itemListId,
          'item_list_name': itemListName,
          'items': items
        }
      });
    },
    addPaymentInfoEvent(currency, value, coupon, paymentType, items) {
      if (typeof(window.dataLayer)==='undefined') { return; }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        'event': 'add_payment_info',
        'ecommerce': {
          'currency': currency,
          'value': value,
          'coupon': coupon,
          'payment_type': paymentType,
          'items': items
        }
      });
    },
    beginCheckoutEvent(currency, value, coupon, items) {
      if (typeof(window.dataLayer)==='undefined') { return; }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        'event': 'begin_checkout',
        'ecommerce': {
          'currency': currency,
          'value': value,
          'coupon': coupon,
          'items': items
        }
      });
    },
    purchaseEvent(currency, value, transactionId, coupon, shipping, tax, items) {
      if (typeof(window.dataLayer)==='undefined') { return; }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        'event': 'purchase',
        'ecommerce': {
          'currency': currency,
          'value': value,
          'transaction_id': transactionId,
          'coupon': coupon,
          'shipping': shipping,
          'tax': tax,
          'items': items
        }
      });
    }
  };

  export default ga4TrackingUtility;