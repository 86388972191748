import braintree from 'braintree-web';
import { makeCatalystFetchPostInit } from '../../../../CRA/src/Components/util/fetchUtility';

let toExport = {};

const errorElementClass = 'has-error';
const personalAccountType = 'personal';
const businessAccountType = 'business';

(function ($) {
    
    function getWalletEditModalConfig() {
        return window["walletConfig"] || {
            tokenUrl: '',
            createUsBankAccountUrl: '',
            requiredAddUsBankAccountText: '',
            generalError: '',
            requestFailed: '',
        };
    }

    // some "readonly" data. see document on DOMContentLoaded
    let submitButton;
    let cancelButton;
    let addForm;
    let state;
    let walletEditModalConfig;

    function getSubmitFormListener(modalConfig) {
        return (event) => {
            event.preventDefault();
            if(!$('#usBankAccountForm').valid())
                return;
            submitButton.setAttribute("disabled", "")
            const billingAddress = {
                streetAddress: addForm.querySelector("#usBankAccountStreetAddress").value,
                extendedAddress: addForm.querySelector("#usBankAccountExtendedAddress").value,
                locality: addForm.querySelector("#usBankAccountLocality").value,
                region: addForm.querySelector("#usBankAccountRegion").value,
                postalCode: addForm.querySelector("#usBankAccountPostalCode").value
            }

            const usBankAccountTypeChecked = addForm.querySelector('input[name="usBankAccountAccountType"]:checked');
            const usBankAccountOwnershipTypeChecked = addForm.querySelector('input[name="usBankAccountOwnershipType"]:checked');

            let bankDetails = {
                accountNumber: addForm.querySelector("#usBankAccountAccountNumber").value,
                routingNumber: addForm.querySelector("#usBankAccountRoutingNumber").value,
                accountType: usBankAccountTypeChecked ? usBankAccountTypeChecked.value : "",
                ownershipType: usBankAccountOwnershipTypeChecked ? usBankAccountOwnershipTypeChecked.value : "",
                billingAddress: billingAddress
            };

            if (bankDetails.ownershipType === personalAccountType) {
                bankDetails.firstName = addForm.querySelector("#usBankAccountFirstName").value;
                bankDetails.lastName = addForm.querySelector("#usBankAccountLastName").value;
            } else {
                bankDetails.businessName = addForm.querySelector('#usBankAccountBusinessName').value;
            }
            
            try {
                modalConfig.usBankAccountInstance.tokenize({
                    bankDetails: bankDetails,
                    mandateText: walletEditModalConfig.requiredAddUsBankAccountText,
                })
                    .then(payload => {
                        createUsBankAccount(walletEditModalConfig.createUsBankAccountUrl, payload.nonce, state.deviceData)
                            .then(result => {
                                if(result) {
                                    hideCardEditModal();
                                    modalConfig.resolve(true);
                                } else {
                                    submitButton.removeAttribute("disabled");
                                }
                            })
                            .catch(error => {
                                alertError(walletEditModalConfig.requestFailed, error);
                                submitButton.removeAttribute("disabled");
                            });
                    })
                    .catch(error => {
                        alertError(walletEditModalConfig.requestFailed, error);
                        submitButton.removeAttribute("disabled");
                    });
            } catch (error) {
                alertError(walletEditModalConfig.generalError, error);
                submitButton.removeAttribute("disabled");
            }

        };
    }

    async function fetchClientToken(url) {
        return await fetch(url, {
            credentials: 'same-origin',
            cache: 'no-cache',
            method: 'POST'
        })
            .then(response => response.json())
            .then(tokenResult => {
                if (!tokenResult.Success)
                    alertError(tokenResult.ErrorMessage);
                else
                    return tokenResult.Token;
            })
            .catch(error => {
                alertError(walletEditModalConfig.requestFailed, error.message)
            });
    }

    async function createUsBankAccount(url, paymentNonce, deviceData) {
        
        return await fetch(url, makeCatalystFetchPostInit({ paymentNonce: paymentNonce, deviceData: deviceData }))
            .then(response => response.json())
            .then(result => {
                if(result && result.Success) {
                    return true;
                }
                if(result && result.ErrorMessage) {
                    alertError(walletEditModalConfig.generalError + " " + result.ErrorMessage);
                    return false;
                }
                alertError(walletEditModalConfig.generalError);
                return false;
            })
            .catch(error => {
                console.error(walletEditModalConfig.requestFailed, error.message)
                return false;
            });
    }

    function isPersonalAccount() {
        return state.accountType === personalAccountType;
    }

    async function showUsBankAccountAddModal() {
        const modal = document.getElementById("usBankAccountModal");

        modal.querySelector('input[name="usBankAccountRoutingNumber"]').value = "";
        modal.querySelector('input[name="usBankAccountAccountNumber"]').value = "";
        modal.querySelectorAll('input[name="usBankAccountAccountType"]').forEach(c => c.checked=false);
        modal.querySelectorAll('input[name="usBankAccountOwnershipType"]').forEach(c => c.checked=false);
        modal.querySelector('input[name="usBankAccountFirstName"]').value = "";
        modal.querySelector('input[name="usBankAccountLastName"]').value = "";
        modal.querySelector('input[name="usBankAccountBusinessName"]').value = "";
        modal.querySelector('input[name="usBankAccountStreetAddress"]').value = "";
        modal.querySelector('input[name="usBankAccountExtendedAddress"]').value = "";
        modal.querySelector('input[name="usBankAccountLocality"]').value = "";
        modal.querySelector('input[name="usBankAccountPostalCode"]').value = "";
        modal.querySelector('select[name="usBankAccountRegion"]').value = "";
        personal(false);
        business(false);

        $(modal).modal('show');

        state.onModalHide = onHideModal(modal);
        $(modal).on('hidden.bs.modal', state.onModalHide)
        
        state.validator = $('#usBankAccountForm').validate({
            rules: {
                usBankAccountRoutingNumber: {
                    required: true,
                    minlength: 9,
                    maxlength: 9,
                    digits: true
                },
                usBankAccountAccountNumber: {
                    required: true,
                    minlength: 4,
                    maxlength: 17,
                    digits: true
                },
                usBankAccountAccountType: {
                    required: true
                },
                usBankAccountOwnershipType: {
                    required: true,
                },
                usBankAccountFirstName: {
                    required: isPersonalAccount,
                },
                usBankAccountLastName: {
                    required: isPersonalAccount
                },
                usBankAccountBusinessName: {
                    required: () => state.accountType === businessAccountType
                },
                usBankAccountLocality: {
                    required: true
                },
                usBankAccountRegion: {
                    required: true
                },
                usBankAccountPostalCode: {
                    required: true
                },
            },
            errorClass: 'invalid-feedback',
            errorPlacement: function (error, element) {
                element.before(error);
            },
            highlight: function (element) {
                $(element.parentNode).addClass(errorElementClass);
            },
            unhighlight: function (element) {
                $(element.parentNode).removeClass(errorElementClass);
            },
        });

        return await GetUsBankAccountResult();
    }

    toExport.showUsBankAccountAddModal = showUsBankAccountAddModal;

    function onHideModal(modal) {
        return () => {
            $(modal).off('hidden.bs.modal', state.onModalHide)
            setLoading(modal, true)
            detachEditListeners()
            deinitModalState();
        }
    }

    function hideCardEditModal() {
        const modal = document.getElementById("usBankAccountModal");
        $(modal).modal('hide');
        onHideModal(modal);
    }

    async function GetUsBankAccountResult() {
        let form = addForm;

        const token = await fetchClientToken(walletEditModalConfig.tokenUrl);
        if (!token) {
            hideCardEditModal(form);
            return;
        }

        return new Promise((resolve, reject) => {
            braintree.client.create({
                authorization: token
            })
                .then(clientInstance => {
                    braintree.dataCollector.create({
                        client: clientInstance
                    }).then(function (dataCollectorInstance) {
                        state.deviceData = dataCollectorInstance.deviceData;
                    });
                    
                    return braintree.usBankAccount.create({
                        client: clientInstance
                    });
                })
                .then(usBankAccountInstance => {
                    initModalState({ usBankAccountInstance, reject, resolve });
                    attachCardEditListeners();
                    submitButton.removeAttribute("disabled");
                    const modal = document.getElementById("usBankAccountModal");
                    setLoading(modal, false)
                })
                .catch(error => {
                    alertError("Could not contact server:\n" + error)
                    hideCardEditModal();
                    resolve('');
                });
        });
    }

    function setLoading(modalElement, isLoading) {
        let modalContent = modalElement.querySelector(".modal-content");
        let loadingDiv = modalElement.querySelector(".loading-wrapper");
        let contentDivs = Array.from(modalContent.children)
            .filter(child => !child.classList.contains("loading-wrapper"));
        if (isLoading) {
            removeClass(loadingDiv, "hidden")
            contentDivs.forEach(div => addClass(div, "hidden"))
        } else {
            addClass(loadingDiv, "hidden")
            contentDivs.forEach(div => removeClass(div, "hidden"))
        }
    }

    function addClass(element, classToAdd) {
        const classes = Array.from(element.classList).filter(cl => cl !== classToAdd);
        element.className = classes.join(" ") + " " + classToAdd;
    }

    function removeClass(element, classToRemove) {
        const classes = Array.from(element.classList).filter(cl => cl !== classToRemove);
        element.className = classes.join(" ");
    }

    function initModalState(modalConfig) {
        state.usBankAccountInstance = modalConfig.usBankAccountInstance;
        state.onSubmit = getSubmitFormListener(modalConfig);
        state.accountType = undefined; 
    }

    function deinitModalState() {
        state.usBankAccountInstance = null;
        state.onSubmit = null;
        state.validator.destroy();
    }

    function attachCardEditListeners() {
        addForm.onsubmit = state.onSubmit;
    }

    function detachEditListeners() {
        const cancelButton = addForm.querySelector('button.cancel');
        cancelButton.onclick = null;
        addForm.onsubmit = null;
    }

    function alertError(error, consoleError) {
        alert(error);
        if(consoleError) {
            console.error(consoleError);
        }
    }
    
    function personal(show) {
        const showHide = show ? removeClass : addClass;
        addForm.querySelectorAll(".account-type-personal").forEach(e => {
            showHide(e, "hidden");
            e.querySelectorAll("input").forEach(e => e.required = show);
        });
    }
    
    function business(show) {
        const showHide = show ? removeClass : addClass;
        addForm.querySelectorAll(".account-type-business").forEach(e => {
            showHide(e, "hidden");
            e.querySelectorAll("input").forEach(e => e.required = show);
        });
    }
    
    function ownershipTypeChange(e) {
        if(e.target.value === personalAccountType) {
            personal(true);
            business(false);
        }

        if(e.target.value === businessAccountType) {
            personal(false);
            business(true);
        }
        
        state.accountType = e.target.value;
    }

    document.addEventListener("DOMContentLoaded", () => {
        walletEditModalConfig = getWalletEditModalConfig();
        addForm = document.getElementById("usBankAccountForm");
        if (addForm) {
            submitButton = addForm.querySelector("button[type='submit']");
            if (submitButton) {
                state = {
                    onSubmit: null,
                    onInputSubmitRequest: submitButton
                        ? () => submitButton.click()
                        : null
                }
            }
            cancelButton = addForm.querySelector("button.cancel");
            cancelButton.onclick = hideCardEditModal;
            
            addForm.querySelectorAll('input[name="usBankAccountOwnershipType"]').forEach(x => x.onchange = ownershipTypeChange);
        }
    });

})(jQuery);

export default toExport;
