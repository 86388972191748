import braintree from 'braintree-web';
import { makeCatalystFetchPostInit } from '../../../../CRA/src/Components/util/fetchUtility';

let toExport = {};

(function ($) {


    function getWalletEditModalConfig() {
        return window["walletConfig"] || {
            tokenUrl: '',
            editCardUrl: '',
            billingAddress: null,
            reenterCardNumber: '',
            placeholderCvv: '',
            placeholderCardNumber: '',
            placeholderExpirationDate: '',
            placeholderCardholderName: '',
            placeholderPostalCode: '',
            usTwoDigitCountryCode: '',
            usThreeDigitCountryCode: '',
            requestFailed: '',
            errorWhileEditingCard: ''
        };
    }

    const ccIconClasses = {
        'american-express': 'cc-icon cc-amex',
        'discover': 'cc-icon cc-discover',
        'master-card': 'cc-icon cc-mastercard',
        'visa': 'cc-icon cc-visa'
    }

    // some "readonly" data. see document on DOMContentLoaded
    let submitButton;
    let cancelButton;
    let editForm;
    let state;
    let walletEditModalConfig;

    function getSubmitFormListener(modalConfig) {
        return (event) => {
            event.preventDefault();
            submitButton.setAttribute("disabled", "")
            const isCardEdit = modalConfig.existingCardData !== null;
            const billingAddress = {
                streetAddress: editForm.querySelector("#streetAddress").value,
                extendedAddress: editForm.querySelector("#extendedAddress").value,
                locality: editForm.querySelector("#locality").value,
                region: editForm.querySelector("#region").value,
                countryCodeAlpha2: walletEditModalConfig.usTwoDigitCountryCode,
                countryCodeAlpha3: walletEditModalConfig.usThreeDigitCountryCode,
            }
            const toTokenize = isCardEdit ? {} : { vault: true, billingAddress: billingAddress };
            // If editing, we just want the cvv nonce, and must include postal code in billing address
            if (isCardEdit) {
                billingAddress.postalCode = editForm.querySelector("#postalCode").value;
            }
            modalConfig.hostedFieldsInstance.tokenize(toTokenize)
                .then(payload => {
                    if (isCardEdit) {
                        if (payload.nonce) {
                            fetchUpdateCard(walletEditModalConfig.editCardUrl, payload.nonce, modalConfig.existingCardData.cardId, billingAddress)
                                .then(result => {
                                    if (result) {
                                        hideCardEditModal();
                                        modalConfig.resolve(payload);
                                    } else {
                                        submitButton.removeAttribute("disabled");
                                    }
                                });
                        } else {
                            alertError(walletEditModalConfig.errorWhileEditingCard);
                            submitButton.removeAttribute("disabled");
                        }
                    } else {
                        hideCardEditModal();
                        modalConfig.resolve(payload);
                    }
                })
                .catch(error => {
                    window.console.error(error);
                    alertError(walletEditModalConfig.errorWhileEditingCard);
                    submitButton.removeAttribute("disabled");
                });
        };
    }

    function cardTypeChangeListener(stateObject) {
        const cardIconElement = editForm.querySelector(".card-icon");
        if (stateObject.cards) {
            const cards = stateObject.cards;
            if (cards.length === 1) {
                const cardData = cards[0];
                let cardType = cardData.type;
                if (cardType) {
                    let ccIconClass = ccIconClasses[cardType];
                    if (!!ccIconClass) {
                        cardIconElement.className = "card-icon " + ccIconClass;
                        cardIconElement.innerHTML = "<span class='sr-only'>" + cardData.niceType + "</span>";
                        return;
                    }
                }
            }
        }
        cardIconElement.className = "card-icon"
        cardIconElement.innerHTML = ""
    }

    const hostedFields = (edit) => {
        if (edit) {
            return {
                'cvv': { wrapper: 'cardCvv' },
                'All': ["cvv"]
            };
        }
        return {
            'number': { wrapper: 'cardNumber' },
            'cvv': { wrapper: 'cardCvv' },
            'expirationDate': { wrapper: 'cardExpirationDate' },
            'postalCode': { wrapper: 'cardPostalCode' },
            'All': ["number", "cvv", "expirationDate", "postalCode"]
        }
    }

    const requiredNonHostedFields = (edit) => {
        const nonHostedFields =
            [
                'streetAddress',
                'locality',
                'region',
            ];

        if (edit) {
            return nonHostedFields.concat('postalCode');
        }
        return nonHostedFields;
    };

    function toggleValid(hostedFieldName, stateObject) {
        let wrapperElement = editForm.querySelector('#' + hostedFields(state.edit)[hostedFieldName].wrapper);
        if (wrapperElement) {
            const fieldData = stateObject.fields[hostedFieldName];
            if (fieldData) {
                if (fieldData.isValid || fieldData.isPotentiallyValid) {
                    removeClass(wrapperElement, 'invalid')
                } else {
                    addClass(wrapperElement, 'invalid')
                }
            }
        }
    }

    function nonHostedFormValidityChangeListener() {
        let allValid = true;
        requiredNonHostedFields(state.edit).forEach(field => {
            const element = editForm.querySelector('[name="' + field + '"]');
            if (!element || !element.value) {
                allValid = false;
            }
        });
        return allValid;
    }

    function formValidityChangeListener(stateObject) {
        let allValid = nonHostedFormValidityChangeListener();
        if (!stateObject || !stateObject.fields) {
            allValid = false;
        } else {
            hostedFields(state.edit).All.forEach(field => {
                if (!stateObject.fields[field] || !stateObject.fields[field].isValid) {
                    allValid = false;
                }
                toggleValid(field, stateObject, editForm)
            });
        }
        updateSubmitButton(allValid)
    }

    function updateSubmitButton(allValid) {
        if (allValid) {
            submitButton.removeAttribute("disabled");
        } else if (!submitButton.hasAttribute("disabled")) {
            submitButton.setAttribute("disabled", "");
        }
    }

    function formFocusChangeListener(stateObject) {
        hostedFields(state.edit).All.forEach(field => {
            const fieldData = stateObject.fields[field];
            const wrapperElement = editForm.querySelector('#' + hostedFields(state.edit)[field].wrapper);
            if (fieldData.isFocused) {
                addClass(wrapperElement, "focused");
            } else {
                removeClass(wrapperElement, "focused");
            }
        });
    }

    async function fetchClientToken(url) {
        return await fetch(url, {
            credentials: 'same-origin',
            cache: 'no-cache',
            method: 'POST'
        })
            .then(response => response.json())
            .then(tokenResult => {
                if (!tokenResult.Success)
                    alertError(tokenResult.ErrorMessage);
                else
                    return tokenResult.Token;
            })
            .catch(error => {
                window.console.error(error);
                alertError(walletEditModalConfig.requestFailed);
            });
    }

    async function fetchUpdateCard(url, cvvNonce, cardId, billingAddress) {
        return await fetch(url, makeCatalystFetchPostInit({
            CvvNonce: cvvNonce,
            BillingAddress: billingAddress,
            CardId: cardId
        }))
            .then(response => response.text() || "")
            .then(errorMessage => {
                if (errorMessage) {
                    alertError(errorMessage);
                    return false;
                }
                return true;
            })
            .catch(error => {
                window.console.error(error);
                alertError(walletEditModalConfig.requestFailed);
                return false;
            });
    }

    function makeHostedFieldsConfig(clientInstance, existingCardData) {

        // only request cvv instance if editing
        const config = {
            client: clientInstance,
            styles: {
                'input': 'braintree-input-class',
            },
            fields: {
                cvv: {
                    selector: "#cardCvv",
                    type: "password",
                    placeholder: walletEditModalConfig.placeholderCvv
                },
            }
        };

        if (existingCardData === null) {
            config.fields.number = {
                selector: "#cardNumber",
                placeholder: walletEditModalConfig.placeholderCardNumber
            };
            config.fields.expirationDate = {
                selector: "#cardExpirationDate",
                placeholder: walletEditModalConfig.placeholderExpirationDate,
                prefill: ''
            };
            config.fields.postalCode = {
                selector: "#cardPostalCode",
                placeholder: walletEditModalConfig.placeholderPostalCode,
                prefill: ''
            };
        }

        return config;

    }

    async function showCardEditModal(modalTitle, submitButtonText, existingCardData) {
        const instance = state.hostedFieldsInstance;
        if (instance)
            instance.teardown();

        const modal = document.getElementById("creditCardModal");
        const modalTitleElement = modal.querySelector(".modal-title");
        modalTitleElement.innerHTML = modalTitle;
        submitButton.innerHTML = submitButtonText;

        modal.querySelector('input[name="streetAddress"]').value = existingCardData && existingCardData.streetAddress ? existingCardData.streetAddress : "";
        modal.querySelector('input[name="extendedAddress"]').value = existingCardData && existingCardData.extendedAddress ? existingCardData.extendedAddress : "";
        modal.querySelector('input[name="locality"]').value = existingCardData && existingCardData.locality ? existingCardData.locality : "";
        modal.querySelector('select[name="region"]').value = existingCardData && existingCardData.region ? existingCardData.region : "";
        modal.querySelector('input[name="postalCode"]').value = existingCardData && existingCardData.postalCode ? existingCardData.postalCode : "";

        $(modal).modal('show');

        state.onModalHide = onHideModal(modal);
        $(modal).on('hidden.bs.modal', state.onModalHide)
        state.edit = !!existingCardData;

        if (state.edit) {
            modal.querySelectorAll('.new-card').forEach(element => { addClass(element, "d-none") });
        } else {
            modal.querySelectorAll('.edit-card').forEach(element => { addClass(element, "d-none") });
        }

        return await GetHostedFieldsResult(existingCardData);
    }

    toExport.showCardEditModal = showCardEditModal;

    function onHideModal(modal) {
        return () => {
            $(modal).off('hidden.bs.modal', state.onModalHide)
            setLoading(modal, true)
            detachCardEditListeners(state.hostedFieldsInstance, modal)
            if (state.hostedFieldsInstance)
                state.hostedFieldsInstance.teardown();
            deinitModalState();
            modal.querySelectorAll('.new-card, .edit-card').forEach(element => { removeClass(element, "d-none") });
        }
    }

    function hideCardEditModal() {
        const modal = document.getElementById("creditCardModal");
        $(modal).modal('hide');
        onHideModal(modal);
    }

    async function GetHostedFieldsResult(existingCardData) {
        let form = editForm;

        const token = await fetchClientToken(walletEditModalConfig.tokenUrl);
        if (!token) {
            hideCardEditModal(form);
            return;
        }

        return new Promise((resolve, reject) => {
            braintree.client.create({
                authorization: token
            })
                .then(clientInstance => {
                    let config = makeHostedFieldsConfig(clientInstance, existingCardData);
                    braintree.hostedFields.create(config)
                        .then(hostedFieldsInstance => {
                            initModalState({ hostedFieldsInstance, resolve, existingCardData, reject });
                            attachCardEditListeners(hostedFieldsInstance, existingCardData);
                            submitButton.removeAttribute("disabled");
                            const modal = document.getElementById("creditCardModal");
                            setLoading(modal, false)
                        });
                })
                .catch(error => {
                    alertError("Could not contact server:\n" + error)
                    hideCardEditModal(form, state.hostedFieldsInstance);
                    resolve('');
                });
        });
    }

    function setLoading(modalElement, isLoading) {
        let modalContent = modalElement.querySelector(".modal-content");
        let loadingDiv = modalElement.querySelector(".loading-wrapper");
        let contentDivs = Array.from(modalContent.children)
            .filter(child => !child.classList.contains("loading-wrapper"));
        if (isLoading) {
            removeClass(loadingDiv, "hidden")
            contentDivs.forEach(div => addClass(div, "hidden"))
        } else {
            addClass(loadingDiv, "hidden")
            contentDivs.forEach(div => removeClass(div, "hidden"))
        }
    }

    function addClass(element, classToAdd) {
        const classes = Array.from(element.classList).filter(cl => cl !== classToAdd);
        element.className = classes.join(" ") + " " + classToAdd;
    }

    function removeClass(element, classToRemove) {
        const classes = Array.from(element.classList).filter(cl => cl !== classToRemove);
        element.className = classes.join(" ");
    }

    function getBinAvailableChangeListener(cardData) {
        return (event) => {
            if (cardData) {
                if (event.bin) {
                    if (event.bin !== cardData.bin) {
                        submitButton.setAttribute("disabled", "");
                        addClass(editForm.querySelector("#cardNumber"), "badBin");
                        alertError("Card number mismatch");
                    } else {
                        submitButton.removeAttribute("disabled");
                        removeClass(editForm.querySelector("#cardNumber"), "badBin");
                    }
                } else {
                    submitButton.setAttribute("disabled", "")
                    removeClass(editForm.querySelector("#cardNumber"), "badBin");
                }
            }
        }
    }

    function initModalState(modalConfig) {
        state.hostedFieldsInstance = modalConfig.hostedFieldsInstance;

        if (modalConfig.existingCardData) {
            state.onBinAvailable = getBinAvailableChangeListener(modalConfig.existingCardData);
        } else {
            state.onBinAvailable = null;
        }

        state.onSubmit = getSubmitFormListener(modalConfig);
    }

    function deinitModalState() {
        state.hostedFieldsInstance = null;

        state.onBinAvailable = null;
        state.onSubmit = null
        state.edit = null;
    }

    function attachCardEditListeners(hostedFieldsInstance, existingCardData) {
        editForm.onsubmit = state.onSubmit;

        hostedFieldsInstance.on('cardTypeChange', cardTypeChangeListener);
        hostedFieldsInstance.on('validityChange', formValidityChangeListener);
        hostedFieldsInstance.on('focus', formFocusChangeListener);
        hostedFieldsInstance.on('blur', formFocusChangeListener);
        hostedFieldsInstance.on('inputSubmitRequest', state.onInputSubmitRequest)
        if (existingCardData) {
            state.onBinAvailable = getBinAvailableChangeListener(existingCardData)
            hostedFieldsInstance.on('binAvailable', state.onBinAvailable);
        }

        editForm.querySelectorAll("input,select").forEach(i => {
            i.addEventListener("blur", function () {
                formValidityChangeListener(state.hostedFieldsInstance._state)
            });
            i.addEventListener("keyup", function () {
                formValidityChangeListener(state.hostedFieldsInstance._state)
            });
        });
    }

    function detachCardEditListeners(hostedFieldsInstance) {
        if (hostedFieldsInstance) {
            hostedFieldsInstance.off('validityChange', formValidityChangeListener);
            hostedFieldsInstance.off('cardTypeChange', cardTypeChangeListener);
            hostedFieldsInstance.off('focus', formFocusChangeListener);
            hostedFieldsInstance.off('blur', formFocusChangeListener);
            hostedFieldsInstance.off('inputSubmitRequest', state.onInputSubmitRequest)
            if (state.onBinAvailable)
                hostedFieldsInstance.off('binAvailable', state.onBinAvailable);
        }

        const cancelButton = editForm.querySelector('button.cancel');
        cancelButton.onclick = null;
        editForm.onsubmit = null;
    }

    function alertError(error) {
        alert(error);
    }


    document.addEventListener("DOMContentLoaded", () => {
        walletEditModalConfig = getWalletEditModalConfig();
        editForm = document.getElementById("creditCardDataForm");
        if (editForm) {
            submitButton = editForm.querySelector("button[type='submit']");
            if (submitButton) {
                state = {
                    onSubmit: null,
                    hostedFieldsInstance: null,
                    onBinAvailable: null,
                    onInputSubmitRequest: submitButton
                        ? () => submitButton.click()
                        : null
                }
            }
            cancelButton = editForm.querySelector("button.cancel");
            cancelButton.onclick = hideCardEditModal;
        }

    });

})(window.jQuery);

export default toExport;
