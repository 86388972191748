(function() {
    document.addEventListener("DOMContentLoaded", function(event) {
        const impersonating = typeof(window.userIsImpersonating) !== "undefined" && !!window.userIsImpersonating;
        const isIframe = window.self !== window.top;
        const onImpersonationPage = typeof(window.impersonationPage) !== "undefined" && !!window.impersonationPage;

        if(impersonating && !isIframe && !onImpersonationPage) {
            const impersonationUrl = "/EPiServer/Verndale.ImpersonateUsers/ImpersonateUsers/Impersonate";
            const relativeURLWithQuery = window.location.pathname + window.location.search;
            window.location.href = `${impersonationUrl}?navigateToUrl=${encodeURI(relativeURLWithQuery)}`;
        }
    });
})();

