let orderHistory = {
    
    async checkElement(selector){
        while (document.getElementById(selector) === null) {
            await new Promise(resolve => requestAnimationFrame(resolve))
        }
        return document.getElementById(selector);
    },
    
     addTimezoneToReceiptUrls(selector) {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.checkElement('myOrdersGrid').then((s) => {
            let myOrdersGrid = document.getElementById('myOrdersGrid');
            let receiptLinks = myOrdersGrid.querySelectorAll(selector);
            if (receiptLinks) {
                let timezoneOffset = new Date().getTimezoneOffset();
                receiptLinks.forEach((currentValue, index, arr) => {
                    let url = new URL(currentValue.href, window.location.origin);
                    url.searchParams.set("timezoneOffset", timezoneOffset);
                    currentValue.href = url.href;
                })
            }
        });
    }
}

export default orderHistory;